<template>
  <div>
    <b-alert variant="primary" show>
      <div class="alert-body">
        <span>ยอดขายที่แสดงในหน้านี้คือ ยอดขายจริงหลักจากหักค่าส่วนต่างแล้ว</span>
      </div>
    </b-alert>
    <b-alert variant="warning" :show="cShowAlertUploadPreview">
      <div class="alert-body">
        <span>จำนวนรูปวอลเปเปอร์ที่อัปโหลดไปแล้วในวันนี้ {{ cCurrentUploadAmountPreview }} รูป</span>
      </div>
    </b-alert>
    <b-row>
      <b-col sm="12" lg="4">
        <b-card>
          <b-skeleton v-if="partnerMainDetails.loading" width="50%" />
          <b-card-title v-else class="mb-1">
            {{ partnerMainDetails.data.partner_info.shop_name }}
            <b-badge
              v-b-tooltip.hover
              pill
              :title="cPartnerLevelHintPreview"
              :variant="cVariantLevel"
              style="font-size: 0.9rem"
              class="mr-50"
            >
              <div class="d-flex align-items-center">
                {{ cPartnerLevelPreview }}
                <feather-icon icon="HelpCircleIcon" size="20" class="ml-50" />
              </div>
            </b-badge>
            <b-button
              v-if="_isAdmin || _isMarketing"
              v-b-tooltip.hover
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              title="แก้ไข Level ของครีเอเตอร์"
              variant="flat-secondary"
              class="btn-icon rounded-circle"
              size="sm"
              @click="showModalEditLevel"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </b-card-title>
          <b-skeleton v-if="partnerMainDetails.loading" width="50%" />
          <b-card-sub-title v-else class="mb-1">
            {{ partnerMainDetails.data.partner_info.email }}
          </b-card-sub-title>

          <table class="w-100">
            <tr>
              <th class="pb-50 w-75">
                <b-badge class="mr-75" variant="success">
                  <feather-icon icon="TrendingUpIcon" />
                </b-badge>
                <span class="font-weight-bold">ยอดขายทั้งหมด</span>
              </th>

              <td class="pb-50 text-right">
                <b-skeleton v-if="partnerMainDetails.loading" width="20%" class="m-0 ml-auto" />
                <span v-else class="text-success">
                  {{ gFormatNumberToCurrency(partnerMainDetails.data.partner_info.sales_revenue) }} บาท
                </span>
              </td>
            </tr>
            <tr>
              <th class="pb-50 w-75">
                <b-badge class="mr-75" variant="danger">
                  <feather-icon icon="TrendingDownIcon" />
                </b-badge>
                <span class="font-weight-bold">ยอดลด (รีฟัน) ทั้งหมด</span>
              </th>

              <td class="pb-50 text-right">
                <b-skeleton v-if="partnerMainDetails.loading" width="20%" class="m-0 ml-auto" />
                <span v-else class="text-danger">
                  {{ gFormatNumberToCurrency(partnerMainDetails.data.partner_info.total_refund) }} บาท
                </span>
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col sm="12" lg="4">
        <b-card>
          <table class="w-100">
            <tr>
              <th class="pb-50 w-75">
                <!-- <feather-icon icon="ImageIcon" class="mr-75" /> -->
                <span class="font-weight-bold">รูปวอลเปเปอร์ทั้งหมด</span>
              </th>

              <td class="pb-50 text-right">
                <b-skeleton v-if="partnerMainDetails.loading" width="20%" class="m-0 ml-auto" />
                <span v-else>{{ gFormatNumberToCurrency(cMainImageData.total) }} รูป</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50 w-75">
                <feather-icon icon="ClockIcon" class="mr-75 text-secondary" />
                <span class="font-weight-bold">รอการอนุมัติทั้งหมด</span>
              </th>

              <td class="pb-50 text-right">
                <b-skeleton v-if="partnerMainDetails.loading" width="20%" class="m-0 ml-auto" />
                <span v-else>{{ gFormatNumberToCurrency(cMainImageData.wait) }} รูป</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50 w-75">
                <feather-icon icon="ClockIcon" class="mr-75 text-warning" />
                <span class="font-weight-bold">กำลังตรวจสอบทั้งหมด</span>
              </th>

              <td class="pb-50 text-right">
                <b-skeleton v-if="partnerMainDetails.loading" width="20%" class="m-0 ml-auto" />
                <span v-else>{{ gFormatNumberToCurrency(cMainImageData.checking) }} รูป</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50 w-75">
                <feather-icon icon="AlertTriangleIcon" class="mr-75 text-danger" />
                <span class="font-weight-bold">ไม่ถูกอนุมัติทั้งหมด</span>
              </th>

              <td class="pb-50 text-right">
                <b-skeleton v-if="partnerMainDetails.loading" width="20%" class="m-0 ml-auto" />
                <span v-else>{{ gFormatNumberToCurrency(cMainImageData.reject) }} รูป</span>
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col sm="12" lg="4">
        <b-card>
          <table class="w-100">
            <tr>
              <th class="pb-50 w-75">
                <!-- <feather-icon icon="ImageIcon" class="mr-75" /> -->
                <span class="font-weight-bold">ราศีทั้งหมด</span>
              </th>

              <td class="pb-50 text-right">
                <b-skeleton v-if="partnerMainDetails.loading" width="20%" class="m-0 ml-auto" />
                <span v-else>{{ gFormatNumberToCurrency(cMainZodiacData.total) }} หมวด</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50 w-75">
                <feather-icon icon="ClockIcon" class="mr-75 text-secondary" />
                <span class="font-weight-bold">รอการอนุมัติทั้งหมด</span>
              </th>

              <td class="pb-50 text-right">
                <b-skeleton v-if="partnerMainDetails.loading" width="20%" class="m-0 ml-auto" />
                <span v-else>{{ gFormatNumberToCurrency(cMainZodiacData.wait) }} หมวด</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50 w-75">
                <feather-icon icon="ClockIcon" class="mr-75 text-warning" />
                <span class="font-weight-bold">กำลังตรวจสอบทั้งหมด</span>
              </th>

              <td class="pb-50 text-right">
                <b-skeleton v-if="partnerMainDetails.loading" width="20%" class="m-0 ml-auto" />
                <span v-else>{{ gFormatNumberToCurrency(cMainZodiacData.checking) }} หมวด</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50 w-75">
                <feather-icon icon="AlertTriangleIcon" class="mr-75 text-danger" />
                <span class="font-weight-bold">ไม่ถูกอนุมัติทั้งหมด</span>
              </th>

              <td class="pb-50 text-right">
                <b-skeleton v-if="partnerMainDetails.loading" width="20%" class="m-0 ml-auto" />
                <span v-else>{{ gFormatNumberToCurrency(cMainZodiacData.reject) }} หมวด</span>
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
    </b-row>

    <b-row cols="1" cols-md="2">
      <b-col>
        <statistic-card-horizontal
          icon="CreditCardIcon"
          :statistic="gFormatNumberToCurrency(cMainBalance)"
          statistic-title="เงินที่ถอนได้"
          :loading="partnerMainDetails.loading"
          color="success"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          icon="CreditCardIcon"
          :statistic="gFormatNumberToCurrency(cMainPendingWithdraw)"
          statistic-title="รอโอนออก"
          :loading="partnerMainDetails.loading"
          color="secondary"
        />
      </b-col>
    </b-row>

    <!-- <pre>{{ partnerMainDetails.data }}</pre> -->

    <b-row class="mb-2" cols="1" cols-sm="2">
      <b-col align-self="center">
        <h3 class="mb-2 mb-md-0">สรุปข้อมูลประจำเดือน</h3>
      </b-col>
      <b-col>
        <div class="d-flex flex-column flex-sm-row justify-content-end align-items-end">
          <p class="m-0 mb-50">เลือกเดือน-ปี</p>
          <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
            <v-select
              v-model="cDate"
              :options="dateSelectOptions"
              :clearable="false"
              :disabled="pageLoading || subDetailsLoading"
              :get-option-label="option => $date(option).format('MM-YYYY')"
            />
          </b-card>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" lg="4">
        <statistic-card-horizontal
          icon="TrendingUpIcon"
          :statistic="gFormatNumberToCurrency(subDetails.sales_revenue || 0)"
          statistic-title="ยอดขาย (หักส่วนต่างแล้ว)"
          :loading="pageLoading || subDetailsLoading"
          color="success"
        />
      </b-col>
      <b-col cols="12" md="6" lg="4">
        <statistic-card-horizontal
          icon="TrendingDownIcon"
          color="warning"
          :statistic="gFormatNumberToCurrency(subDetails.af_revenue || 0)"
          statistic-title="ยอดหักค่าคอมมิชชั่น AF"
          :loading="pageLoading || subDetailsLoading"
        />
      </b-col>
      <b-col cols="12" md="6" lg="4">
        <statistic-card-horizontal
          icon="TrendingDownIcon"
          :statistic="gFormatNumberToCurrency(subDetails.total_refund || 0)"
          statistic-title="ยอดลด (รีฟัน)"
          :loading="pageLoading || subDetailsLoading"
          color="danger"
        />
      </b-col>

      <b-col cols="12">
        <statistic-card-horizontal
          icon="ImageIcon"
          :statistic="gFormatNumberToCurrency(subDetails.count_image || 0)"
          statistic-title="จำนวนรูปวอลเปเปอร์ที่ขายได้"
          :loading="pageLoading || subDetailsLoading"
        />
      </b-col>
      <!-- <b-col>
        <statistic-card-horizontal
          icon="ImageIcon"
          :statistic="gFormatNumberToCurrency(subDetails.count_zodiac || 0)"
          statistic-title="หมวดราศี"
          :loading="pageLoading || subDetailsLoading"
        />
      </b-col> -->
    </b-row>

    <!-- <pre>{{ subDetails }}</pre> -->

    <b-card>
      <div class="d-flex align-items-center justify-content-between">
        <b-card-title class="mb-1">สรุปยอดขาย</b-card-title>
        <b-card-sub-title>รูป / ยอดขาย</b-card-sub-title>
      </div>

      <vue-apex-charts type="bar" height="350" :options="cChartOptions" :series="cChartSeries" />
    </b-card>

    <b-modal
      ref="refModalEditLevelPartner"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      title="แก้ไข Level ของครีเอเตอร์"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      @ok="confirmEditLevelPartner"
    >
      <label for="demo-sb">Level</label>
      <b-form-spinbutton v-model="partnerLevel" min="0" max="99" />
      <div class="d-flex mt-50 justify-content-between">
        <b-button size="sm" variant="danger" @click="partnerLevel = 0">ปรับเป็น 0</b-button>
        <b-button size="sm" variant="success" @click="partnerLevel = 99">ปรับเป็น 99</b-button>
      </div>
      <b-alert variant="primary" show class="mt-1">
        <div class="alert-body">
          <p>
            <strong>
              เลเวล 0 หมายถึง
            </strong>
            ระงับการใช้งานบางอย่าง เช่น อัปโหลดรูป แก้ไขรูป สร้างแคมเปญ Redeem ขอถอนเงิน และอื่นๆ
          </p>

          <p>
            <strong>
              เลเวล 1 ขึ้นไป หมายถึง
            </strong>
            จำนวนรูปวอลเปเปอร์ที่สามารถอัปโหลดได้ในแต่ละวัน
          </p>

          <p>
            <strong>
              เลเวล 99 หมายถึง
            </strong>
            ไม่จำกัดการอัปโหลดรูปต่อวัน & สามารถใช้ระบบสร้าง Redeem Code ได้
          </p>
        </div>
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    StatisticCardHorizontal,
    vSelect,
    VueApexCharts,
  },
  props: {
    userId: {
      type: String,
      default: '',
    },
    dateSelectOptions: {
      type: Array,
      default: () => [],
    },
    dateSelected: {
      type: String,
      default: null,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      partnerMainDetails: {
        data: {},
        loading: false,
      },
      subDetails: {},
      subDetailsLoading: false,
      partnerLevel: 0,
    }
  },
  computed: {
    ...mapState('AuthStore', ['userInfo']),
    ...mapGetters('AuthStore', ['_isAdmin', '_isMarketing']),
    cDate: {
      get() {
        return this.dateSelected
      },
      set(val) {
        this.$emit('changeDateSelect', val)
      },
    },
    cChartSeries() {
      if (this.subDetails?.dashboards?.overview_sales_venue) {
        return [{ data: [...this.subDetails.dashboards.overview_sales_venue.data] }]
      }

      return [{ data: [] }]
    },
    cChartOptions() {
      let categories = []

      if (this.subDetails?.dashboards?.overview_sales_venue) {
        categories = [...this.subDetails.dashboards.overview_sales_venue.categories]
      }

      return {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#5d5fef',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '30%',
            // endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories,
        },
        yaxis: {
          // opposite: isRtl,
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
          },
          y: {
            formatter: value => this.gFormatNumberToCurrency(value),
            title: {
              formatter: () => 'ยอดขาย',
            },
          },
        },
      }
    },
    cMainImageData() {
      return this.partnerMainDetails.data?.images ?? {}
    },
    cMainZodiacData() {
      return this.partnerMainDetails.data?.zodiacs ?? {}
    },
    cMainBalance() {
      return this.partnerMainDetails.data?.partner_info?.credit_balance ?? 0
    },
    cMainPendingWithdraw() {
      return this.partnerMainDetails.data?.partner_info?.wait_transfer ?? 0
    },
    cPartnerLevelPreview() {
      const n = Number(this.partnerMainDetails.data?.partner_info?.amount_upload_per_day)
      if (n === 99) return 'Unlimited'
      if (n > 0) return `Level ${n}`

      return 'ถูกระงับการใช้งาน'
    },
    cPartnerLevelHintPreview() {
      const n = Number(this.partnerMainDetails.data?.partner_info?.amount_upload_per_day)

      if (n === 99) return 'ไม่จำกัดการอัปโหลดรูปต่อวัน & สามารถใช้ระบบสร้าง Redeem Code ได้'
      if (n > 0) return 'เลข Level บอกถึงจำนวนรูปวอลเปเปอร์ที่อัปโหลดได้ต่อวัน'

      return 'ถูกระงับการใช้งานบางส่วน เนื่องจากแอดมินตรวจพบการทำผิดกฏการให้บริการของ Ongphra Wallpaper Creator โปรดติดต่อแอดมินเพื่อแก้ไขปัญหานี้'
    },
    cVariantLevel() {
      const n = Number(this.partnerMainDetails.data?.partner_info?.amount_upload_per_day)
      if (n === 0) return 'danger'
      if (n === 99) return 'success'
      return 'primary'
    },
    cCurrentUploadAmountPreview() {
      return this.partnerMainDetails.data?.partner_info?.upload_limit_used
    },
    cShowAlertUploadPreview() {
      if (this.userInfo.role_data_preview === 'admin') return true
      if (this.partnerMainDetails.loading) return false
      if (this.partnerMainDetails.data?.partner_info?.amount_upload_per_day !== 0) return true
      return false
    },
  },
  watch: {
    // userId(newVal) {
    //   console.log('newVal', newVal)
    // },
    dateSelected(newVal) {
      this.getPartnerSubDetailsByDate(this.userId, newVal)
    },
  },
  async created() {
    this.getPartnerSubDetailsByDate(this.userId, this.dateSelected)
    this.getPartnerMainDetails(this.userId)
  },
  methods: {
    async getPartnerSubDetailsByDate(id, date) {
      if (!id || !date) return
      this.$emit('updatePageLoading', true)
      this.subDetailsLoading = true
      const resp = await this.api.get(`api/partner/dashboard/filter/${id}/${date}`).catch(() => null)
      // console.log('getPartnerSubDetailsByDate', resp)
      if (resp) {
        this.subDetails = { ...resp }
      }
      this.$emit('updatePageLoading', false)
      this.subDetailsLoading = false
    },
    async getPartnerMainDetails(id) {
      this.partnerMainDetails.loading = true
      const resp = await this.api.get(`api/partner/dashboard/${id}`).catch(() => null)
      // console.log('getPartnerMainDetails', resp)
      if (resp) {
        this.partnerMainDetails.data = { ...resp }
      }
      this.partnerMainDetails.loading = false
    },
    showModalEditLevel() {
      this.$refs.refModalEditLevelPartner.show()
      this.partnerLevel = Number(this.partnerMainDetails.data?.partner_info?.amount_upload_per_day)
    },
    async confirmEditLevelPartner(bvEvent) {
      await bvEvent.preventDefault()
      this.gOpenPageLoading()
      const resp = await this.api.postV2('api/admin/change-amount-upload-limit-per-day', {
        amount_upload_per_day: this.partnerLevel,
        user_id: this.userId,
      })
      this.gClosePageLoading()
      if (resp && resp?.code === 200) {
        this.$refs.refModalEditLevelPartner.hide()
        this.gDisplayToast('แก้ไข Level สำเร็จแล้ว')
        await this.getPartnerMainDetails(this.userId)
      } else {
        this.gDisplayToast('ไม่สามารถแก้ไข Level ได้', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/chart-apex.scss';
</style>
